/* global lozad, toastr */

// Init
$(function () {
  // Start lazy load
  const observer = lozad('.lozad', {
    loaded: function () {
      // Responsive video
      videoAutoplay()
    },
  })

  observer.observe()

  // Init mobile navigation
  mobileNavigation()

  $('[data-toggle="navbar"]').on('click', mobileNavigationToggle)
  $(document).on('click', '.overlay', mobileNavigationHide)

  // Change product quantity
  $('.quantity--input').on(
    'change',
    { operation: false, isInput: true },
    setIntQuantity
  )
  $('.quantity--plus').on(
    'click',
    { operation: 'plus', isInput: false },
    setIntQuantity
  )
  $('.quantity--minus').on(
    'click',
    { operation: 'minus', isInput: false },
    setIntQuantity
  )
})

// Functions
function videoAutoplay() {
  if ($(window).outerWidth() > 768) {
    const playPromise = $('#videoBg').length
      ? $('#videoBg').get(0).play()
      : undefined

    if (playPromise !== undefined) {
      playPromise.catch((error) => console.log(error))
    }
  } else {
    $('#videoBg').attr('autoplay', false)
  }
}

// Mobile navigation
function mobileNavigation() {
  $('#navbarMobile, .overlay').remove()

  $('body')
    .append(
      '<div id="navbarMobile" class="navbar-mobile" /><div class="navbar-mobile-body" />'
    )
    .append('<div class="overlay" />')

  $('.logo, .menu, .telephone, .languages')
    .clone()
    .addClass('mobile')
    .appendTo('#navbarMobile .navbar-mobile-body')
}

// Открытие/Закрытие мобильного навигационного меню
function mobileNavigationToggle() {
  $(this).toggleClass('open')
  setTimeout(() => $('#navbarMobile').toggleClass('open'), 200)

  $('.overlay').css('right', '')
  $('body').css('paddingRight', '').removeClass('overflow-hidden')

  if ($(this).hasClass('open')) {
    let scrollBarWidth = $(window).outerWidth() - $(window).width()

    $('.overlay').fadeIn('fast').css('right', scrollBarWidth)
    $('body').addClass('overflow-hidden').css('paddingRight', scrollBarWidth)
  } else {
    setTimeout(() => $('.overlay').fadeOut('fast'), 200)
  }
}

// Скрытие мобильного навигационного меню
function mobileNavigationHide() {
  $('[data-toggle="navbar"]').removeClass('open')
  $('#navbarMobile').removeClass('open')
  $('.overlay').css('right', '').fadeOut('fast')

  $('body').css('paddingRight', '').removeClass('overflow-hidden')
}

// Изменение количества товара
function setIntQuantity(event) {
  let qtyVal

  if (event.data.isInput) {
    qtyVal = parseInt($(this).val().match(/\d+/g), 10)
    $(this).val(qtyVal > 0 ? qtyVal : 1)
  } else {
    const inputElement = $(this).parent().find('.quantity--input')
    qtyVal = parseInt(inputElement.val().match(/\d+/g), 10)

    if (event.data.operation == 'plus') {
      ++qtyVal
    } else if (event.data.operation == 'minus' && qtyVal > 1) {
      --qtyVal
    }

    inputElement.val(qtyVal)
  }
}

// Добавление товара в корзину
// eslint-disable-next-line no-unused-vars
const cart = {
  add: function (productId) {
    const quantity = $('#quantity' + productId).val()

    $.ajax({
      url: `index.php?route=checkout/cart/add`,
      type: `post`,
      data: `product_id=${productId}&quantity=${
        typeof quantity !== `undefined` ? quantity : 1
      }`,
      dataType: `json`,
      success(json) {
        $(`.alert, .text-danger`).remove()

        // if (json.redirect) {
        //   location = json.redirect
        // }

        if (json.success) {
          toastr.success(json.success)

          // Need to set timeout otherwise it wont update the total
          setTimeout(function () {
            $(`#cartCounter`).html(json.total)
          }, 100)

          $(`#cart .cart--content`).load(
            `index.php?route=common/cart/info .cart--content li`
          )
        }
      },
    })
  },
  remove: function (key) {
    $.ajax({
      url: `index.php?route=checkout/cart/remove`,
      type: `post`,
      data: `key=${key}`,
      dataType: `json`,
      success(json) {
        // Need to set timeout otherwise it wont update the total
        setTimeout(function () {
          $(`#cartCounter`).html(json.total)
        }, 100)

        $(`#cart .cart--content`).load(
          `index.php?route=common/cart/info .cart--content li`
        )
      },
    })
  },
}
